<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <!--  -->
      <v-container>
        <v-row>
          <v-col md="2.1" class="mr-2" v-if="returnAbility('edu-class:store')">
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="green"
                  class="white--text"
                >
                  <v-icon size="20">mdi-plus</v-icon>
                  {{ $t("add educitional class") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center card-title__heading">
                  <h2>{{ $t("add educitional class") }}</h2>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    :label="$t('name')"
                    v-model="newEducationClass.name"
                    outlined
                    dense
                    color="#757575"
                    type="text"
                  ></v-text-field>
                  <v-autocomplete
                    v-model="newEducationClass.grade_id"
                    outlined
                    dense
                    :items="grades"
                    :label="$t('class')"
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-model="newEducationClass.room_id"
                    outlined
                    dense
                    :items="rooms"
                    :label="$t('room')"
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="green"
                    class="white--text"
                    @click="submitNewEduClass"
                    :loading="submitBtnLoading"
                    :disabled="disableSubmitBtn"
                    >{{ $t("storage") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col md="3">
            <v-text-field
              v-model="nameSearch"
              :label="$t('search by name')"
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-autocomplete
              hide-details
              v-model="searchRoomId"
              outlined
              dense
              :items="rooms"
              :label="$t('serach by room')"
              item-text="name"
              item-value="id"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col md="3" class="d-flex justify-center align-center">
            <v-autocomplete
              hide-details
              v-model="searchGradeId"
              outlined
              dense
              :items="grades"
              :label="$t('search by class')"
              item-text="name"
              item-value="id"
              clearable
            ></v-autocomplete>
            <v-icon class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <!--  -->
      <app-base-table
        @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)"
        :serverRoute="'/edu-class'"
        :enableDelete="displayDeleteIcon"
        :tableOptions="tableOptions"
      >
        <template slot="editFeature" slot-scope="{ row }">
          <v-icon
            v-if="returnAbility('edu-class:show')"
            color="green"
            @click="$router.push(`edu-class/info/${row.id}`)"
            >mdi-eye</v-icon
          >
          <app-edit-education-class-modal
            v-if="returnAbility('edu-class:update')"
            @recordUpdated="fetchData"
            :recordData="row"
          ></app-edit-education-class-modal>
        </template>
      </app-base-table>
      <v-row>
      <v-col md="9" class="mr-2"></v-col>
    <v-col md="2" class="mr-2">
          <v-switch
          @change="goToAll"    
          v-model="is_all"
          color="green"
          :label="$t('Show All')"
        ></v-switch>
        </v-col>
    </v-row>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import editEducationClassModal from "./editEducationClassModal.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    appEditEducationClassModal: editEducationClassModal,
  },
  data() {
    return {
      is_all: false,
      paginated: true,
      nameSearch: "",
      searchGradeId: null,
      searchRoomId: null,
      dialog: false,
      submitBtnLoading: false,
      loading: false,
      newEducationClass: {
        name: "",
        grade_id: null,
        room_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      rooms: "room/getRooms",
      grades: "grade/getGrades",
      tableOptions: "educationClass/getTableOptions",
    }),

    disableSubmitBtn() {
      if (
        !this.newEducationClass.name ||
        !this.newEducationClass.grade_id ||
        !this.newEducationClass.room_id
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchRooms: "room/fetchAllRooms",
      fetchGrades: "grade/fetchAllGrades",
      fetchEducationClasses: "educationClass/fetchEducationClass",
    }),
    goToAll(){
      if(this.is_all) {
        this.paginated = false;
      } else { this.paginated = true; }
      this.fetchData();
    },
    async submitNewEduClass() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/edu-class", this.newEducationClass);
        this.$Notifications(
          this.$t("add success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
        this.dialog = false;
      } catch (err) {
      } finally {
        this.submitBtnLoading = false;
      }
    },

    fetchData(paginationValue) {
      this.fetchEducationClasses({
        page: paginationValue,
        paginated: this.paginated,
        name: this.nameSearch,
        grade_id: this.searchGradeId,
        room_id: this.searchRoomId,
      });
    },
  },
  created() {
    if (this.returnAbility("edu-class:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchGrades();
    this.fetchRooms();
    this.fetchData(1);
  },
};
</script>

<style>
</style>