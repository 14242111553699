<template>
  <v-dialog v-model="editDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t('edit') }} {{ recordData.name }}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field :label="$t('name')" v-model="editEducationClass.name" outlined dense color="#757575"
          type="text"></v-text-field>
        <v-autocomplete v-model="editEducationClass.grade" outlined dense :items="grades" :label="$t('mainview.class')"
          item-text="name" item-value="id" return-object></v-autocomplete>
        <v-autocomplete v-model="editEducationClass.room" outlined dense :items="rooms" :label="$t('room')"
          item-text="name" item-value="id" return-object></v-autocomplete>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn :disabled="disableEditBtn" color="green" class="white--text" depressed @click="editRecord"
          :loading="editBtnLoading">{{ $t('edit') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      editEducationClass: {
        name: "",
        grade: null,
        room: null,
      },
      editBtnLoading: false,
      editDialog: false,
    };
  },
  watch: {
    editDialog() {
      this.editEducationClass.name = this.recordData.name;
      this.editEducationClass.grade = this.recordData.grade;
      this.editEducationClass.room = this.recordData.room;
    },
  },
  props: {
    recordData: Object,
  },

  methods: {
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(`/edu-class/${this.recordData.id}`, {
          name: this.editEducationClass.name,
          grade_id: this.editEducationClass.grade.id,
          room_id: this.editEducationClass.room.id,
        });
        this.$Notifications(
          this.$t('edit success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
      } finally {
        this.editBtnLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      rooms: "room/getRooms",
      grades: "grade/getGrades",
    }),
    disableEditBtn() {
      if (!this.editEducationClass.name) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.editEducationClass.name = this.recordData.name;
    this.editEducationClass.grade = this.recordData.grade;
    this.editEducationClass.room = this.recordData.room;
  },
};
</script>

<style>

</style>